.layout-topbar .layout-topbar-icons .layout-topbar-search-container {
  text-align: right;
}

.layout-topbar .layout-topbar-icons .p-menu .layout-topbar-search-container {
  text-align: left;
  padding: 10px;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search {
  top: -2px;
  margin-left: 20px;
}

.layout-topbar .layout-topbar-icons .p-menu .layout-topbar-search {
  margin-left: 0px;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search input {
  font-size: 1.1rem !important;
  width: 155px !important;
  padding: 1px 34px 1px 1px !important;

  @media screen and (max-width: 768px) {
    width: 120px !important;
  }  
}

.layout-topbar .layout-topbar-icons .p-menu .layout-topbar-search input {
  width: 100% !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
}

.layout-topbar .layout-topbar-icons .p-menu .layout-topbar-search:hover input {
    border-bottom-color: #000;
}
.layout-topbar .layout-topbar-icons .p-menu .layout-topbar-search input::-webkit-input-placeholder {
  color: #6d6d6d !important;
}  
.layout-topbar .layout-topbar-icons .p-menu .layout-topbar-search input:-moz-placeholder { /* Firefox 18- */
  color: #6d6d6d !important;  
}   
.layout-topbar .layout-topbar-icons .p-menu .layout-topbar-search input::-moz-placeholder {  /* Firefox 19+ */
  color: #6d6d6d !important;  
}   
.layout-topbar .layout-topbar-icons .p-menu .layout-topbar-search input:-ms-input-placeholder {  
  color: #6d6d6d !important;  
}

.layout-topbar .layout-topbar-icons .layout-topbar-search .layout-topbar-search-icon {
  top: 2px;
}

.layout-topbar .layout-topbar-icons .p-menu .layout-topbar-search:hover .layout-topbar-search-icon {
  color: rgba(0, 0, 0, 0.87);
}

.layout-topbar .layout-topbar-icons .layout-topbar-search .layout-topbar-search-icon.mouse-pointer {
  cursor: pointer;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search-results {
  position: absolute;
  margin-top: 5px; 
  margin-right: 5px;  
  z-index: 10;

  @media screen and (min-width: 450px) {
    margin-left: 20px;
  }  
}

.layout-topbar .layout-topbar-icons .p-menu .layout-topbar-search-results {
  left: 0;
  margin-right: 0;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search-results .place-list-item {
  text-align: left;
}

.layout-topbar .layout-topbar-icons .layout-topbar-search-results .place-list-item > i {
  float: left;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search-results .place-list-item > span {
  display: block;
  margin-left: 23px;
}
