.locale-selector.p-dropdown.p-component.p-inputwrapper {
  margin-top: -10px;
  border: none;
}

.locale-selector.p-dropdown.p-dropdown-label {
  padding: 0.3em 0.1em;
}

.locale-selector.p-dropdown {
  background: transparent;
  margin-left: 10px;
}

.locale-selector.p-dropdown .p-dropdown-label {
  padding: 0.5em;
  padding-top: 0px;
  padding-bottom: 0px;
}

.locale-selector.p-dropdown .p-hidden-accessible.p-dropdown-hidden-select,
.locale-selector.p-dropdown .p-dropdown.p-dropdown-label,
.locale-selector.p-dropdown .p-dropdown-label.p-inputtext,
.locale-selector.p-dropdown .p-dropdown-trigger {
  color: white;
}

.locale-selector.p-dropdown:not(.p-disabled):hover,
.locale-selector.p-dropdown:not(.p-disabled):focus,
.locale-selector.p-dropdown:not(.p-disabled).p-focus
{
  border-color: transparent;
  box-shadow: unset;
}

.locale-selector.p-dropdown .p-dropdown-trigger {
  width: 1.7rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-left: 1px solid white;
}

.locale-selector button.locale-selected {
  font-weight: bold;
}

.locale-selector button.p-button-link,
.locale-selector button.p-button-text {  
  margin-left: unset !important;
  min-width: unset !important;  
  padding: 0px 5px !important;
}

.locale-selector button.p-button-link > span.p-button-label {
  color: white
}
.locale-selector button.p-button-link.locale-selected > span.p-button-label {
  text-decoration: underline;
}

.p-menu .locale-selector.p-dropdown.p-component.p-inputwrapper {
  margin-top: 0;
}

.p-menu .locale-selector.p-dropdown {
  margin-left: 0;
  border: none;
}


.p-menu .locale-selector.p-dropdown .p-hidden-accessible.p-dropdown-hidden-select,
.p-menu .locale-selector.p-dropdown .p-dropdown.p-dropdown-label,
.p-menu .locale-selector.p-dropdown .p-dropdown-label.p-inputtext, 
.p-menu .locale-selector.p-dropdown .p-dropdown-trigger {
  color: rgba(0, 0, 0, 0.87);
}

.p-menu .locale-selector.p-dropdown .p-dropdown-trigger {
  border-left: 1px solid rgba(0, 0, 0, 0.87);
}

.layout-topbar .layout-topbar-icons .p-menu button {
  color: rgba(0, 0, 0, 0.87);
}